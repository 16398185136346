<template>
  <div class="main index" v-if="!isLoading">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSaveClicked)">
        <div>
          <div class="columns is-multiline">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Bank Soal</b></h1>
            </div>
            <div class="column is-10">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Bank Soal</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `newLevels` }">
                      <h3 class="is-size-6">Tag</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3
                      class="is-size-6 breadcrumb-item"
                      v-if="$route.name != 'editNewTags'"
                    >
                      Create Tag
                    </h3>
                    <h3 class="is-size-6 breadcrumb-item" v-else>
                      Edit Tag
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column is-2">
              <b-field grouped position="is-right">
                <b-button type="is-hcc" native-type="submit" icon-left="save">
                  Save
                </b-button>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="box">
                <!-- For Name Field -->
                <div class="columns is-multiline">
                  <div class="column is-2">
                    <b>Name</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      name="name"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-field :type="errors.length > 0 ? 'is-danger' : null">
                        <b-input v-model="data.name"></b-input>
                      </b-field>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'form-category',
  data() {
    return {
      isLoading: true,
      data: {
        id: null,
        name: null,
      },
    }
  },
  async created() {
    if (this.$route.name == 'editNewTags') {
      const response = await this.fetchTag(this.$route.params.newTagId)

      this.data = response.data
    }

    this.isLoading = false
  },
  methods: {
    ...mapActions({
      fetchTag: 'newTag/fetchTag',
      createTag: 'newTag/createTag',
      updateTag: 'newTag/updateTag',
    }),

    async onSaveClicked() {
      let data = {
        id: this.data.id,
        name: this.data.name,
      }

      try {
        if (this.$route.name == 'createNewTags') {
          await this.createTag(data)
          this.success('Create Tag Success')
        } else {
          await this.updateTag(data)
          this.success('Update Tag Success')
        }

        this.$router.push('/new-tag')
      } catch (err) {
        this.danger(err.response.data.message)
      }
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>
